//React, React Router, Formik
import React, {useEffect} from 'react';
import {useRouter} from "next/router";

//Material UI

//Redux
import {useDispatch} from "react-redux";
import {makeUserLogin} from "@store/Auth/actions";
import {setSnackbar2} from "@store/ui/actions";

//Assets

const useSocialAuth = () => {

    const dispatch = useDispatch();
    const router = useRouter();

    return (
        useEffect(() => {

            /*const queryParams = new URLSearchParams(location.search);
            const accessToken = queryParams.get('access_token');
            const message = queryParams.get('social_auth_message');
            const redirectPath = queryParams.get('redirect') ?? '';*/

            //const {accessToken, message, redirectPath} = router.query;
            const {access_token, message} = router.query;
            const redirectPath = router.query.redirect ?? '';

            //For Social Auth
            if (message) {
                dispatch(setSnackbar2(message));
            }

            if (access_token) {
                dispatch(makeUserLogin(access_token, null, router));
            }

        }, [])
    );
};

export default useSocialAuth;
