//React, React Router, Formik
import React, {useRef} from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import CourseCard2 from "@ui/CourseCard2/CourseCard2";
import Carousel from "react-multi-carousel";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        /*width: '100%',
        maxWidth: 1100,
        padding: theme.spacing(0, 18),
        margin: 'auto'*/

        width: '100%',
        maxWidth: 1375,
        marginLeft: 'auto',
        padding: theme.spacing(0, 0, 0, 18)
    },
    carouselContainer: {
        "& .react-multi-carousel-list": {
            "& .react-multi-carousel-item": {
                maxWidth: 384
            }
        },
        "& .react-multi-carousel-dot-list": {
            position: 'unset',
            bottom: 'unset',
            marginTop: theme.spacing(1),
            "& .react-multi-carousel-dot button": {
                background: theme.palette.grey.iron,
                border: 'none',
            },
            "& .react-multi-carousel-dot--active button": {
                background: theme.palette.grey.persian_blue
            },
        }
    },
    pageContainer: {
        ...theme.typography.lmsContainer
    },
    cardWrapper: {
        padding: theme.spacing(3, 2),
    },
    listHeader: {
        marginLeft: theme.spacing(2),
    },
    leftArrow: {
        ...theme.typography.carouselArrowButton,
        position: 'absolute',
        left: 0,
    },
    rightArrow: {
        ...theme.typography.carouselArrowButton,
        position: 'absolute',
        right: 0,
    },
    prevNavButton: {
        transform: 'rotate(180deg)'
    }
}));

const CourseList = ({products}) => {

    const classes = useStyles();

    const responsive = {
        desktop: {
            breakpoint: {max: 4000, min: 768},
            items: 3,
            slidesToSlide: 3,
            partialVisibilityGutter: 50
        },
        tablet: {
            breakpoint: {max: 767, min: 600},
            items: 2,
            slidesToSlide: 2,
            partialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: {max: 599, min: 0},
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 50
        }
    };

    const ArrowFix = (arrowProps) => {
        const {carouselState, children, ...restArrowProps} = arrowProps;
        return (<IconButton {...restArrowProps}> {children} </IconButton>);
    };

    return (

        <div className={classes.root}>
            <div className={classes.carouselContainer}>
                <Carousel
                    responsive={responsive}
                    showDots={true}
                    renderDotsOutside={true}
                    arrows={true}
                    removeArrowOnDeviceType={["mobile"]}
                    partialVisible={true}
                    customLeftArrow={
                        <ArrowFix className={`${classes.carouselArrowButton} ${classes.leftArrow}`}>
                            <ArrowForwardIosIcon className={classes.prevNavButton}/>
                        </ArrowFix>
                    }
                    customRightArrow={
                        <ArrowFix className={`${classes.carouselArrowButton} ${classes.rightArrow}`}>
                            <ArrowForwardIosIcon/>
                        </ArrowFix>
                    }
                >
                    {
                        products.map(product => (
                            <div key={product} className={classes.cardWrapper}>
                                <CourseCard2 {...product}/>
                            </div>
                        ))
                    }
                </Carousel>
            </div>
        </div>
    );
};

export default CourseList;
