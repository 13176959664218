import React, {useEffect, useState} from "react";
//import withGlobalData, {getStaticProps} from "@util/withGlobalData/withGlobalData";
import nookies from "nookies";
import {AUTH,} from "@helper/constants/routes";
import {getApiUrl} from "@helper/constants/constant";
import LandingPage from "@modules/landing-page/LandingPage";
import ZS from "@modules/Index/ZS/ZS";
import Head from "next/head";
import {getDomain} from "@helper/functions";
import Auth from "@modules/Index/Auth/Auth";
import Guest from "@modules/Index/Guest/Guest";

//Material UI

//Redux
import {useSelector} from "react-redux";
import {wrapper} from "@store/store";
import {welcomeActions} from "@store/Welcome/slice";
import {UnsetAccessToken} from "@store/Auth/actions";
import {productActions} from "@store/Product/slice";

//This method must be implemented for Nprogress to work properly
export const getServerSideProps = wrapper.getServerSideProps(
    store => async (context) => {

        const cookies = nookies.get(context);

        //Get access token either from cookie or query param of response of payment gateway
        const accessToken = cookies.access_token || (context.query.access_token ? `Bearer ${context.query.access_token}` : undefined);

        let domain = getDomain(context.req.headers.host);

        if (accessToken) {

            //Set cookie if access token is available
            nookies.set(context, 'access_token', accessToken, {
                maxAge: 365 * 24 * 60 * 60,
                path: '/',
            });

            //If payment gateway response has "from" attr in query params
            //then redirect to that path
            if (context.query.from) {
                return {
                    redirect: {
                        permanent: false,
                        destination: context.query.from
                    }
                }
            }


        }
        if (domain === 'onlinevideobooks' && accessToken) {
            try {
                const response = await fetch(`${getApiUrl(context)}/api/welcome/authWelcomeData`, {
                    method: 'GET',
                    headers: {
                        'Authorization': accessToken,
                    }
                });
                const data = await response.json();
                store.dispatch(welcomeActions.setAuthWelcomeData(data));

            } catch (e) {
                console.log('EXC: ', e);
                UnsetAccessToken();
                return {
                    redirect: {
                        permanent: false,
                        destination: AUTH.login,
                    },
                    props: {},
                };
            }
        }

        if (domain === 'zeeshanshaikh') {
            try {
                const response = await fetch(`${getApiUrl(context)}/api/products/show`, {
                    method: 'GET',
                    headers: {
                        'Authorization': cookies.access_token,
                    }
                });
                const data = await response.json();
                store.dispatch(productActions.setShowData(data));

            } catch (e) {
                console.log('EXC: ', e);
                UnsetAccessToken();
                return {
                    redirect: {
                        permanent: false,
                        destination: AUTH.login,
                    },
                    props: {},
                };
            }
        }
    }
)

const Index = (props) => {

    const [finalComponent, setFinalComponent] = useState(null);

    const {isAuthenticated} = useSelector(state => state.auth);
    const {appMeta} = useSelector(state => state.siteDetail);

    useEffect(() => {

        let domain = getDomain(window.location.href);

        if (domain === 'onlinevideobooks') {
            if (isAuthenticated) {
                setFinalComponent(<Auth/>)
            } else {
                setFinalComponent(<Guest/>)
            }
        } else if (domain === 'dbc') {
            setFinalComponent(<LandingPage/>)
        } else {
            setFinalComponent(<ZS/>)
        }
    }, [isAuthenticated])

    return (
        <>
            <Head>
                <title>{appMeta?.appName}</title>
            </Head>
            {finalComponent}
        </>
    );
}

//export default withGlobalData(Index)
export default Index
