//React, React Router, Formik
import React, {useRef} from 'react';
import Hero from "./Hero/Hero";
import LearnFromAnywhere from "./LearnFromAnywhere/LearnFromAnywhere";
import GetStartedButton from "@ui/GetStartedButton/GetStartedButton";
import DiscoverLearning from "./DiscoverLearning/DiscoverLearning";
import CarouselGallery from "../../../Layout/CarouselGallery/CarouselGallery";
import useCarouselItems from "./useCarouselItems";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";

//Redux
import useSocialAuth from "@util/hooks/useSocialAuth/useSocialAuth";

//Assets

const useStyles = makeStyles(theme => ({

}));

const Guest = () => {

    const embeddedImageRef = useRef(null);

    //Or handleSocialAuth API for making user logging after social authentication
    useSocialAuth();

    return (
        <Grid container style={{marginTop: '-43px'}}>

            {/*Hero*/}
            <Grid item style={{width: '100%'}}>
                <Hero embeddedImageRef={embeddedImageRef}/>
            </Grid>

            {/*Discover Learning*/}
            <Grid item xs={12}>
                <DiscoverLearning />
            </Grid>

            {/*Carousel Gallery*/}
            <Grid item xs={12}>
                {/*Hide Temporarily*/}
                {/*<CarouselGallery variant="large" carouselItems={carouselItems}/>*/}
            </Grid>

            {/*Learn From Anywhere*/}
            <Grid item xs={12}>
                <LearnFromAnywhere />
            </Grid>

            {/*Get Started Button*/}
            <GetStartedButton targetElementRef={embeddedImageRef}/>
        </Grid>
    );
};

export default Guest;
