import axiosInstance from "@helper/axios";
import {mobileWelcomeActions} from "./slice";
import {uiActions} from "@store/ui/slice";

const basePath = '/mobile/welcome';

export const authWelcomeData = () => {
    return dispatch => {
        axiosInstance.get(`${basePath}/authWelcomeData`)
            .then(response => {
                console.log('POST MESSAGE CALLED', JSON.stringify(response));
                ReactNativeWebView.postMessage(JSON.stringify(response));
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                console.log(error.response);
            })
    }
}
