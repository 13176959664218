import {createSlice} from "@reduxjs/toolkit";

const initialState = {

}

const slice = createSlice({
    name: 'mobile.welcome',
    initialState,
    reducers: {

    },
});

export const mobileWelcomeActions = slice.actions;

export default slice.reducer;
