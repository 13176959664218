//React, React Router, Formik
import React, {useEffect} from 'react';
import {useRouter} from "next/router";

//Redux
import {useDispatch} from "react-redux";
import {uiActions} from "@store/ui/slice";
import {updatePaymentStatus} from "@store/Payment/StripeActions";
import {authWelcomeData} from "@store/MobileApi/actions";
import {handleCancelledPayment} from "@store/Payment/PaypalAction";

//Assets

const useAfterPayment = () => {

    const dispatch = useDispatch();
    const router = useRouter();

    return (
        useEffect(() => {

            const {SESSION_ID, STATUS, PAYTM_RESPONSE_VERIFIED, RESPCODE, ORDER_ID} = router.query;

            // For Stripe
            if (SESSION_ID) {
                dispatch(updatePaymentStatus(SESSION_ID, router));
            }
            if (STATUS === "PAYMENT_SUCCESSFUL") {

                if (navigator.userAgent.indexOf('mobile-app') !== -1) {
                    dispatch(authWelcomeData());
                }

                dispatch(uiActions.setSuccessApiResponse({message: 'Your payment was successful'}))
            }

            //For Paytm
            if (STATUS === "TXN_FAILURE") {
                if (!PAYTM_RESPONSE_VERIFIED) {
                    //updatePaytmPayment();
                }
                if (RESPCODE === "141") {
                    dispatch(uiActions.setErrorApiResponse({message: 'You have cancelled the payment, please try again'}))
                } else {
                    dispatch(uiActions.setErrorApiResponse({message: 'Payment Failed, Please try again'}))
                }
            }

            //For paypal
            if (ORDER_ID) {
                dispatch(handleCancelledPayment(ORDER_ID));
                dispatch(uiActions.setErrorApiResponse({message: 'You have cancelled the payment, please try again'}))
            }

        }, [])
    );
};

export default useAfterPayment;
