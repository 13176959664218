import axiosInstance from "@helper/axios";
import {uiActions} from "@store/ui/slice";
import {CHECKOUT, ORDER} from "@helper/constants/routes";
import {passRouteParams} from "@helper/functions";

export const checkoutWithPaypal = (formData) => {
    return dispatch => {

        dispatch(uiActions.togglePurchaseSpinner({spinner: true}));

        axiosInstance.post(CHECKOUT.store, formData)
            .then(response => {

                console.log(response);
                if (response.subscription) {
                    window.location.href = response.subscription.links.href;
                }

                dispatch(uiActions.togglePurchaseSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                console.log(error);
                dispatch(uiActions.togglePurchaseSpinner({spinner: false}));
            })
    }
}

export const payWithPaypal = (formData, orderId) => {
    return dispatch => {

        dispatch(uiActions.togglePurchaseSpinner({spinner: true}));

        axiosInstance.post(passRouteParams(ORDER.store, {id: orderId}), formData)
            .then(response => {

                window.location.href = response.subscription.links.href;

            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                console.log(error);
                dispatch(uiActions.togglePurchaseSpinner({spinner: false}));
            })
    }
}

//Updating Payment Status
export const updatePaymentStatus = (sessionId, history) => {

    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`${basePath}/updateStripePayment`, {
            'session_id': sessionId
        })
            .then(response => {
                console.log(response);
                history.push('/?STATUS=PAYMENT_SUCCESSFUL')
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                console.log(error);
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })

    }
}

export const handleCancelledPayment = (orderId) => {
    return dispatch => {
        axiosInstance.post(`payments/handlePaypalCancelledPayment`, {order_id: orderId})
            .then(response => {
            })
            .catch(([error, status]) => {
                console.log(error);
            })
    }
}
