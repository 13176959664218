//React, React Router, Formik
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Banner from "./Banner/Banner";

//Material UI
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

//Redux

//Assets


const useStyles = makeStyles(theme => ({
    root: {
        marginBlock: theme.spacing(-10.75, 8),
    },
    indicatorContainer: {
        position: 'absolute',
        bottom: '30px'
    },
    indicatorIconButton: {
        padding: theme.spacing(0, 0.5),
        "& .MuiSvgIcon-root": {
            fontSize: '18px',
            fill: theme.palette.grey.iron
        }
    },
    activeIndicatorIconButton: {
        "& .MuiSvgIcon-root": {
            fill: theme.palette.grey.persian_blue
        }
    },
    navButtonsWrapper: {
        "&:hover": {
            "& .MuiIconButton-root": {
                opacity: 1,
                backgroundColor: '#FFF',
            }
        },
    },
    navButtons: {
        background: '#FFF',
        "& .MuiSvgIcon-root": {
            fill: theme.palette.primary.main
        },
        "&:hover": {
            opacity: [1, '!important'],
            backgroundColor: '#FFF',
            cursor: 'pointer'
        }
    },
    prevNavButton: {
        transform: 'rotate(180deg)'
    }
}));

const CarouselGallery = ({variant, carouselItems, carouselItemRef}) => {

    const classes = useStyles();
    const theme = useTheme();

    const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Carousel
            className={classes.root}
            autoPlay={true}
            // timeout={0}
            animation="slide"
            indicators
            indicatorContainerProps={{className: classes.indicatorContainer}}
            indicatorIconButtonProps={{className: classes.indicatorIconButton}}
            activeIndicatorIconButtonProps={{className: classes.activeIndicatorIconButton}}
            fullHeightHover={false}
            navButtonsAlwaysVisible={!matchesDownSm}
            navButtonsWrapperProps={{className: classes.navButtonsWrapper}}
            navButtonsProps={{className: classes.navButtons}}
            PrevIcon={<ArrowForwardIosIcon className={classes.prevNavButton} />}
            NextIcon={<ArrowForwardIosIcon />}
        >
            {
                carouselItems.map( (item, i) => (
                    <Banner variant={variant} key={i} {...item} carouselItemRef={carouselItemRef}/>
                ))
            }
        </Carousel>
    );
};

export default CarouselGallery;
