//React, React Router, Formik
import React, {useState} from 'react';
import {passRouteParams} from "@helper/functions";
import {PRODUCT} from "@helper/constants/routes";
import Link from "next/link";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Grid, Typography} from "@material-ui/core";
import MenuButton from "./MenuButton/MenuButton";
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import {useSelector} from "react-redux";
import CustomImage from "@util/CustomImage/CustomImage";

//Redux

//Assets


const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.primary.main,
        "&:hover": {
            textDecoration: 'none'
        }
    },
    root: {
        ...theme.typography.lmsCard,
        display: 'flex',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
    },
    details: {
        padding: theme.spacing(3, 3, 1)
    },
    imageWrapper: {
        position: 'relative',
        width: '100%',
        "& img": {
            //width: [224, '!important'],
            height: '100%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            }
        },
        "& .MuiSvgIcon-root": {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            "&:nth-child(2)": {
                width: 55,
                height: 55,
                color: theme.palette.primary.main
            },
            "&:nth-child(3)": {
                width: 32,
                height: 32,
                color: '#FFF'
            },
        },
        "& .MuiLinearProgress-root": {
            background: theme.palette.primary.main,
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: 8
        }
    },
    chapterNumber: {
        color: theme.palette.grey.limed_spruce,
        letterSpacing: '1px'
    },
    chapterTitle: {
        fontWeight: 'bold',
        minHeight: 48,
        margin: theme.spacing(1, 0),
        display: '-webkit-box',
        overflow: 'hidden',
        boxOrient: 'vertical',
        lineClamp: 2,
        textOverflow: 'ellipsis'
    },
    footer: {
        marginTop: 'auto'
    },
    authorName: {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: 1.38,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingRight: 3
    },
    duration: {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: 1.38,
        color: theme.palette.grey.persian_blue,
        whiteSpace: 'nowrap',
    },
}));

const ResumeCard = (props) => {

    const classes = useStyles();

    const assetPath = useSelector(state => state.siteDetail.assetPath);

    const {
        id,
        title,
        product_slug,
        chapter_order,
        thumbnail,
        chapters_count,
        instructors,
        duration,
        chapter_duration_left,
        progress_percentage
    } = props;
    const thumbnailUrl = thumbnail?.url || `${assetPath}upload-image-placeholder.png`;

    const [hoveredThumbnail, setHoveredThumbnail] = useState(false);

    const handleMouseOver = (state) => {
        setHoveredThumbnail(state);
    }

    return (
        <Link
            href={passRouteParams(PRODUCT.view, {slug: product_slug}, `?target_chapter_id=${id}`)}
            passHref
        >
            <a className={classes.link}>
                <Card className={classes.root}>

                    <div
                        className={classes.imageWrapper}
                        onMouseEnter={() => handleMouseOver(true)}
                        onMouseLeave={() => handleMouseOver(false)}
                    >
                        <CustomImage
                            loaded={!!assetPath}
                            src={thumbnailUrl}
                            alt="Thumbnail"
                            //layout="raw"
                            width={1280}
                            height={720}
                        />
                        {
                            hoveredThumbnail &&
                            <>
                                <FiberManualRecordRoundedIcon/>
                                <PlayArrowRoundedIcon/>
                            </>

                        }
                        <LinearProgress
                            variant="determinate"
                            color="secondary"
                            value={progress_percentage}
                        />
                    </div>


                    <Grid container className={classes.details}>

                        {/*Lesson No & Options*/}
                        <Grid item container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h6" className={classes.chapterNumber}>
                                    <b>LESSON {chapter_order} OF {chapters_count}</b> | {duration}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <MenuButton chapterId={id}/>
                            </Grid>
                        </Grid>

                        {/*Chapter Title*/}
                        <Grid item>
                            <Typography variant="h4" className={classes.chapterTitle}>
                                {title}
                            </Typography>
                        </Grid>

                        {/*Author Name & Duration*/}
                        <Grid item container wrap="nowrap" justifyContent="space-between" className={classes.footer}>
                            <Grid item className={classes.authorName}>
                                {
                                    instructors && instructors.map((instructor, index, instructors) => (
                                        `${instructor.full_name}${index + 1 === instructors.length ? '' : ', '}`
                                    ))
                                }
                            </Grid>
                            <Grid item className={classes.duration}>
                                {chapter_duration_left} left
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </a>
        </Link>
    );
};

export default ResumeCard;
