import {productActions} from "./slice";
import {uiActions} from "@store/ui/slice";
import axiosInstance from "@helper/axios";
import {store as storeBookmark, destroy as deleteBookmark} from "@store/Bookmark/actions";
import nookies from "nookies";
import {userActions} from "@store/Operator/User/slice";


const basePath = '/products';

//Todo: To be deleted because calling from SSR
export const view = (productSlug, targetChapterId) => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.get(`${basePath}/${productSlug}/view`, {
            params: {
                target_chapter_id: targetChapterId,
                conversion: '1280x720',
            }
        })
            .then(response => {
                dispatch(productActions.setViewData(response));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                if (status === 404) {
                    dispatch(productActions.resetViewData(error));
                } else {
                    dispatch(uiActions.setErrorApiResponse(error));
                }
                dispatch(uiActions.toggleSpinner({spinner: false}));
                console.log(error.response);
            })
    }
}

export const getRelatedProducts = (productSlug) => {
    return dispatch => {
        axiosInstance.get(`${basePath}/${productSlug}/relatedProducts`, {
            params: {
                conversion: '1280x720'
            }
        })
            .then(response => {
                dispatch(productActions.setRelatedProducts(response));
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                console.log(error.response);
            })
    }
}

export const addBookmark = (productId, slice) => {
    return dispatch => {

        dispatch(productActions.toggleBookmark({productId, status: true, slice}));
        dispatch(storeBookmark(productId));
    }
}

export const removeBookmark = (productId, slice) => {
    return dispatch => {

        dispatch(productActions.toggleBookmark({productId, status: false, slice}));
        dispatch(deleteBookmark(productId));
    }
}

export const loadChapter = (targetChapter, activeChapter) => {

    return dispatch => {
        if (targetChapter.video_source === 'EMBED' && !targetChapter.embed_url) {
            return;
        } else if (targetChapter.video_source === 'UPLOAD' && !targetChapter.video?.url) {
            return;
        }

        document.body.scrollTop = document.documentElement.scrollTop = 0;

        dispatch(productActions.updateProduct({chapterId: activeChapter.id}));

        dispatch(productActions.setActiveChapter({
            chapterId: targetChapter.id
        }));
    }
}
