//React, React Router, Formik
import React, {useState} from 'react';
import SocialAuthButton from "@ui/SocialAuthButton/SocialAuthButton";
import SignupForm from "@ui/SignupForm/SignupForm";
import {TERMS_PRIVACY_AND_HELP} from "@helper/constants/routes";
import Link from "next/link";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

//Redux
import {useSelector} from "react-redux";
import CustomImage from "@util/CustomImage/CustomImage";

//Assets

const useStyles = makeStyles(theme => ({
    heroRoot: {
        position: 'relative',
        height: 'auto',
        width: '100%',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up(541)]: {
            minHeight: 838
        },
        [theme.breakpoints.up(810)]: {
            minHeight: 576
        },
        [theme.breakpoints.up(990)]: {
            minHeight: 598
        },
        [theme.breakpoints.up(809)]: {
            flexDirection: 'column'
        },
    },
    authContainer: {
        margin: theme.spacing(2),
        width: '40%',
        [theme.breakpoints.down(810)]: {
            width: '100%',
            margin: 0,
        },
    },
    authContent: {
        maxWidth: 346,
        padding: theme.spacing(8, 6),
        color: '#FFF',
    },
    headerWrapper: {
        marginBottom: theme.spacing(2),
        "& .MuiTypography-root": {
            fontFamily: 'Kumlien Pro',
            fontSize: '32px',
            lineHeight: '36px',
        }
    },
    captionWrapper: {
        "& .MuiTypography-root": {
            lineHeight: '24px',
        }
    },
    authBtnWrapper: {
        width: '100%',
        margin: theme.spacing(10, 0, 0)
    },
    signupTextBtnWrapper: {
        marginBottom: theme.spacing(4),
        textAlign: 'center',
        "& $signupTextBtn": {
            color: theme.palette.secondary.main
        }
    },
    signupTextBtn: {},
    infoText: {
        textAlign: 'center',
        fontSize: '13px',
        lineHeight: '20px',
        "&::selection": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main
        },
        "& a": {
            fontSize: '12px',
            fontWeight: 'bold',
            color: ['#FFF', '!important'],
        }
    },

    embeddedImage: {
        "& .custom-image": {

        },
        minWidth: 595,
        position: 'absolute',
        left: '40%',
        minHeight: 200,
        backgroundPosition: 'left',
        zIndex: 3,
        [theme.breakpoints.up(541)]: {
            marginTop: 0,
            height: 288,
        },
        [theme.breakpoints.up(810)]: {
            top: 36,
            left: '40%',
            width: '60%',
            minWidth: 486,
            height: 640,
            borderRadius: '16px 0 0 16px',
        },
        [theme.breakpoints.up(990)]: {
            top: 19,
            left: '40%',
            minWidth: 595,
            height: 678,
        },
        [theme.breakpoints.down(810)]: {
            position: 'unset',
            borderRadius: 'unset',
            width: '100%',
            minWidth: 'unset',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        },
    }
}));

const Hero = ({embeddedImageRef}) => {

    const classes = useStyles();

    const {assetPath, appMeta} = useSelector(state => state.siteDetail);

    const [showSignUpForm, setShowSignUpForm] = useState(false);

    const handleShowSignUpForm = () => {
        setShowSignUpForm(true);
    }

    return (
        <Grid container className={classes.heroRoot}>

            {/*Hero Image*/}
            <Grid item container className={classes.embeddedImage} ref={embeddedImageRef}
                style={{background: assetPath ? `url(${assetPath}embedded-hero.webp)` : '#E4D6D3'}}
            />

            {/*<Grid item container className={classes.embeddedImage} ref={embeddedImageRef}>
                <CustomImage
                    loaded={!!assetPath}
                    src={`${assetPath}embedded-hero.webp)`}
                    alt="Hero Image"
                    layout="fill"
                    objectFit="cover"
                />
            </Grid>*/}

            {/*Auth Section*/}
            <Grid item container justifyContent="center" className={classes.authContainer}>

                {/*Content*/}
                <Grid item container direction="column" alignItems="center" className={classes.authContent}>

                    {/*Header*/}
                    <Grid item className={classes.headerWrapper}>
                        <Typography align="center">
                            Start watching books like movies or web series
                        </Typography>
                    </Grid>

                    {/*Caption*/}
                    <Grid item className={classes.captionWrapper}>
                        <Typography variant="h4" align="center">
                            Start your learning journey
                        </Typography>
                    </Grid>

                    {/*Social Auth Button*/}
                    <Grid item className={classes.authBtnWrapper}>
                        <SocialAuthButton variant="dark"/>
                    </Grid>

                    {/*Signup Button*/}
                    <Grid item className={classes.signupTextBtnWrapper}>
                        {
                            !showSignUpForm &&
                            <Button variant="text" size="large" onClick={handleShowSignUpForm}
                                    className={classes.signupTextBtn}>
                                Sign up using Email
                            </Button>
                        }
                        {showSignUpForm && <SignupForm/>}
                    </Grid>

                    {/*Info*/}
                    <Grid item>
                        <Typography className={classes.infoText}>
                            By signing up you agree to {appMeta?.appName}&apos;s
                            <Link href={TERMS_PRIVACY_AND_HELP.terms} target="_blank"> Terms of Service</Link> and <Link
                            href={TERMS_PRIVACY_AND_HELP.privacy} target="_blank">Privacy Policy</Link>.
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>

        </Grid>
    );
};

export default Hero;
