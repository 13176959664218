//React, React Router, Formik
import React, {useEffect, useRef} from 'react';
//import CarouselGallery from "../../../Layout/CarouselGallery/CarouselGallery";
import CourseCard from "../../../ui/CourseCard/CourseCard";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//import carouselItems from "./CarouselItems";
import GetStartedButton from "@ui/GetStartedButton/GetStartedButton";
import GetStartedButton2 from "@ui/GetStartedButton2/GetStartedButton2";
import useAfterPayment from "@util/hooks/useAfterPayment/useAfterPayment";

//Material UI
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, Typography, useMediaQuery} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import ResumeChapterList from "./ResumeChapterList/ResumeChapterList";

//Redux
import {useDispatch, useSelector} from "react-redux";
import {getAuthWelcomeData} from "@store/Welcome/actions";
import {addBookmark, removeBookmark} from "@store/Welcome/actions";

//Assets

const useStyles = makeStyles(theme => ({
    lmsRow: {
        padding: theme.spacing(10.5, 5, 6.5),
        [theme.breakpoints.down('499')]: {
            padding: theme.spacing(10.5, 0, 6.5),
        }
    },
    carouselContainer: {
        "& .react-multi-carousel-list": {
            paddingTop: theme.spacing(6),
            "& .react-multi-carousel-track ": {
                [theme.breakpoints.down(412)]: {
                    flexDirection: 'column'
                }
            }
        },
        "& .react-multi-carousel-dot-list": {
            position: 'unset',
            bottom: 'unset',
            marginTop: theme.spacing(1),
            "& .react-multi-carousel-dot button": {
                borderRadius: '100px',
                width: '16px',
                height: '4px',
                background: theme.palette.grey.iron,
                border: 'none'
            },
            "& .react-multi-carousel-dot--active button": {
                background: theme.palette.grey.persian_blue
            },
        }
    },
    pageContainer: {
        ...theme.typography.lmsContainer
    },
    membershipAd: {
        padding: theme.spacing(0, 7),
        [theme.breakpoints.down('499')]: {
            padding: theme.spacing(0, 2),
        }
    },
    membershipAdBox: {
        width: 'fit-content',
        padding: theme.spacing(0, 5),
        borderRadius: 8,
        boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-evenly',
            alignItems: 'start'
        }
    },
    membershipAdTextWrapper: {
        padding: theme.spacing(3, 0, 2),
        "& .MuiTypography-root": {
            fontFamily: 'Kumlien Pro',
            fontSize: '18px'
        },
    },
    membershipBtnWrapper: {
        padding: theme.spacing(2, 0, 3)
    },
    cardWrapper: {
        padding: theme.spacing(3, 2),
    },
    listHeader: {
        marginLeft: theme.spacing(2),
        // paddingLeft: theme.spacing(7)
    },
    leftArrow: {
        ...theme.typography.carouselArrowButton,
        position: 'absolute',
        left: 0,
    },
    rightArrow: {
        ...theme.typography.carouselArrowButton,
        position: 'absolute',
        right: 0,
    },
    prevNavButton: {
        transform: 'rotate(180deg)'
    }
}));

const Auth = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));

    //const carouselItemRef = useRef(null);
    const recommendedForYouRef = useRef(null);

    const auth_user = useSelector(state => state.auth.auth_user);
    const {recommendedProducts, featuredProducts, trendingProducts, resumeChapters} = useSelector(state => state.welcome.authWelcomeData);

    /*Fetch welcome page data for auth users*/
    //Todo: Uncomment dispatch for "getAuthWelcomeData" call
    useEffect(() => {
        //dispatch(getAuthWelcomeData());
    }, []);

    const responsive = {
        desktop: {
            breakpoint: {max: 4000, min: 768},
            items: 4,
            slidesToSlide: 4,
            //partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: {max: 767, min: 413},
            items: 2,
            slidesToSlide: 2,
            //partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: {max: 412, min: 0},
            items: 1,
            slidesToSlide: 1,
            //partialVisibilityGutter: 40
        }
    };

    const ArrowFix = (arrowProps) => {
        const {carouselState, children, ...restArrowProps} = arrowProps;
        return (<IconButton {...restArrowProps}> {children} </IconButton>);
    };

    //Showing Message for Failed or Success Payment (Paytm)
    //Or Calling Stripe API for Updating Payment
    useAfterPayment();

    return (
        <>
            {/*Payment Success Message*/}
            {/*<Snackbar2 />*/}

            {/*Hide Temporarily*/}
            {/*<CarouselGallery carouselItems={carouselItems} carouselItemRef={carouselItemRef}/>*/}

            <div className={classes.pageContainer}>

                {/*Membership Button*/}
                {
                    !auth_user?.has_membership &&
                        <div className={classes.membershipAd}>
                            <Grid container direction="column" className={classes.membershipAdBox}>
                                <Grid item className={classes.membershipAdTextWrapper}>
                                    <Typography>
                                        Start watching books like movies or web series
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.membershipBtnWrapper}>
                                    <GetStartedButton2 />
                                </Grid>
                            </Grid>
                        </div>
                }

                {/*Resume Card*/}
                {resumeChapters && resumeChapters.length ? <ResumeChapterList resumeChapters={resumeChapters}/> : null}

                {/*Recommended Courses*/}
                {
                    recommendedProducts && recommendedProducts.length ?
                        <div className={classes.lmsRow}>

                            <Typography className={classes.listHeader} variant="h2" ref={recommendedForYouRef}>
                                Recommended For You
                            </Typography>

                            <div className={classes.carouselContainer}>
                                <Carousel
                                    responsive={responsive}
                                    showDots={matchesUpMd}
                                    renderDotsOutside={true}
                                    arrows={true}
                                    removeArrowOnDeviceType={["mobile"]}
                                    customLeftArrow={
                                        <ArrowFix className={`${classes.carouselArrowButton} ${classes.leftArrow}`}>
                                            <ArrowForwardIosIcon className={classes.prevNavButton}/>
                                        </ArrowFix>
                                    }
                                    customRightArrow={
                                        <ArrowFix className={`${classes.carouselArrowButton} ${classes.rightArrow}`}>
                                            <ArrowForwardIosIcon/>
                                        </ArrowFix>
                                    }
                                >
                                    {
                                        recommendedProducts.map(product => (
                                            <div key={product.product_id} className={classes.cardWrapper}>
                                                <CourseCard {...product} slice="welcomeProducts" addBookmark={addBookmark} removeBookmark={removeBookmark}/>
                                            </div>
                                        ))
                                    }
                                </Carousel>
                            </div>
                        </div> : null
                }

                {/*Featured On Skillshare*/}

                {
                    featuredProducts && featuredProducts.length ?
                        <div className={classes.lmsRow}>

                            <Typography className={classes.listHeader} variant="h2">
                                Featured On Online Video Books
                            </Typography>

                            <div className={classes.carouselContainer}>
                                <Carousel
                                    responsive={responsive}
                                    showDots={matchesUpMd}
                                    renderDotsOutside={true}
                                    arrows={true}
                                    removeArrowOnDeviceType={["mobile"]}
                                    customLeftArrow={
                                        <ArrowFix className={`${classes.carouselArrowButton} ${classes.leftArrow}`}>
                                            <ArrowForwardIosIcon className={classes.prevNavButton}/>
                                        </ArrowFix>
                                    }
                                    customRightArrow={
                                        <ArrowFix className={`${classes.carouselArrowButton} ${classes.rightArrow}`}>
                                            <ArrowForwardIosIcon/>
                                        </ArrowFix>
                                    }
                                >
                                    {

                                        featuredProducts.map(product => (
                                            <div key={product.product_id} className={classes.cardWrapper}>
                                                <CourseCard {...product} slice="welcomeProducts" addBookmark={addBookmark} removeBookmark={removeBookmark}/>
                                            </div>
                                        ))
                                    }
                                </Carousel>
                            </div>
                        </div> : null
                }

                {/*Trending Now*/}
                {
                    trendingProducts && trendingProducts.length ?
                        <div className={classes.lmsRow}>

                            <Typography className={classes.listHeader} variant="h2">
                                Trending Now
                            </Typography>

                            <div className={classes.carouselContainer}>
                                <Carousel
                                    responsive={responsive}
                                    showDots={matchesUpMd}
                                    renderDotsOutside={true}
                                    arrows={true}
                                    removeArrowOnDeviceType={["mobile"]}
                                    customLeftArrow={
                                        <ArrowFix className={`${classes.carouselArrowButton} ${classes.leftArrow}`}>
                                            <ArrowForwardIosIcon className={classes.prevNavButton}/>
                                        </ArrowFix>
                                    }
                                    customRightArrow={
                                        <ArrowFix className={`${classes.carouselArrowButton} ${classes.rightArrow}`}>
                                            <ArrowForwardIosIcon/>
                                        </ArrowFix>
                                    }
                                >
                                    {
                                        trendingProducts.map(product => (
                                            <div key={product.product_id} className={classes.cardWrapper}>
                                                <CourseCard {...product} slice="welcomeProducts" addBookmark={addBookmark} removeBookmark={removeBookmark}/>
                                            </div>
                                        ))
                                    }
                                </Carousel>
                            </div>
                        </div> : null
                }

            </div>

            {/*Get Started Button*/}
            {!auth_user?.has_membership && <GetStartedButton targetElementRef={recommendedForYouRef}/>}
        </>
    );
};

export default Auth;
