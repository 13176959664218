//React, React Router, Formik
import React from 'react';

//Material UI
import {alpha, makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, useMediaQuery} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    carouselItem: {
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: props => props.variant === 'large' ? 598 : 'initial',
        minHeight: '400px',
        padding: theme.spacing(7.5),
        [theme.breakpoints.down('sm')]: {
            height: '598px'
        }
    },
    fadeBackground: {
        background: `linear-gradient(90deg, ${alpha('#000', 0.8)} 0%, transparent 100%)`
    },
    bannerContent: {
        maxWidth: '1140px',
        margin: 'auto'
    },
    title: {
        color: '#FFF',
        fontSize: '36px',
        fontWeight: 'bold',
        lineHeight: 1.22,
        [theme.breakpoints.down('md')]: {
            fontSize: '26px',
            lineHeight: 1.23,
        }
    },
    largeTitle: {
        color: '#FFF',
        fontSize: '48px',
        fontWeight: 'bold',
        lineHeight: '52px',
        [theme.breakpoints.down('md')]: {
            fontSize: '26px',
            lineHeight: '32px',
        }
    },
    description: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: theme.spacing(4, 0, 0),
        paddingBottom: theme.spacing(4),
        color: '#FFF'
    },
    largeDescription: {
        fontSize: '22px',
        lineHeight: '28px',
        margin: theme.spacing(4, 0, 0),
        paddingBottom: theme.spacing(4),
        color: '#FFF'
    },
}));

const Banner = ({carouselItemRef, ...props}) => {

    const classes = useStyles(props);
    const theme = useTheme();

    const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid
            ref={carouselItemRef}
            container
            className={classes.carouselItem}
            style={{backgroundImage: matchesDownSm ? `linear-gradient(90deg, ${alpha('#000', 0.5)}, ${alpha('#000', 0.5)}), url(${props.responsiveBanner})` : `linear-gradient(90deg, ${alpha('#000', 0.8)}, transparent 100%), url(${props.banner})`}}
        >
            <div className={classes.fadeBackground}></div>
            <Grid
                item
                container
                direction="column"
                justifyContent="center"
                className={classes.bannerContent}
            >
                <Grid item>
                    <Typography className={props.variant === 'large' ? classes.largeTitle : classes.title}>
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item className={props.variant === 'large' ? classes.largeDescription : classes.description}>
                    {props.description}
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                    >
                        Watch Now
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Banner;
