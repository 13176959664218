//React, React Router, Formik
import React from 'react';
import CourseCard4 from "@ui/CourseCard4/CourseCard4";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";

//Redux
import {useSelector} from "react-redux";
import {addBookmark, removeBookmark} from "@store/Product/actions";

//Assets

const useStyles = makeStyles(theme => ({
    pageContainer: {
        ...theme.typography.lmsContainer,
        paddingBottom: [theme.spacing(10), '!important']
    },
    cardWrapper: {
        padding: theme.spacing(3, 2),
    },
}));

const ZS = () => {

    const classes = useStyles();

    const {products} = useSelector(state => state.product.showData);

    return (
        <div className={classes.pageContainer}>
            <Grid container>
                {
                    products && products.map(product => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={product.product_id} className={classes.cardWrapper}>
                            <CourseCard4 {...product} slice="allProducts" addBookmark={addBookmark}
                                         removeBookmark={removeBookmark}/>
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    );
};

export default ZS;
