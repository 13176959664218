//React, React Router, Formik
import React from 'react';

//Material UI
import {alpha, makeStyles} from '@material-ui/core/styles';
import {Grid, Hidden, Typography} from "@material-ui/core";

//Redux
import {useSelector} from "react-redux";
import CustomImage from "@util/CustomImage/CustomImage";

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        height: 905,
        padding: theme.spacing(18.75, 0),
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(22.75, 0),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 0, 22.75),
        },
    },
    lfaWrapper: {
        margin: theme.spacing(31.25, 'auto'),
        maxWidth: 1280,
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(22, 0),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(22, 0, 18),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(22, 0, 13),
        },
    },
    contentWrapper: {
        width: 416,
        minWidth: 416,
        marginLeft: theme.spacing(18),
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(12),
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: 'auto',
            margin: theme.spacing(14, 0, 0, 6)
        },
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
            marginLeft: 0,
            padding: theme.spacing(0, 12)
        },
    },
    headerWrapper: {
        "& .MuiTypography-root": {
            fontSize: '48px',
            fontWeight: 'bold',
            lineHeight: '52px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '36px',
                lineHeight: '44px',
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center'
            },
        }
    },
    separatorWrapper: {
        "& hr": {
            backgroundColor: theme.palette.grey.persian_blue,
            borderRadius: '8px',
            width: 100,
            height: 8,
            margin: theme.spacing(6, 0)
        }
    },
    descriptionWrapper: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: 444
        },
        "& .MuiTypography-root": {
            fontSize: '22px',
            lineHeight: '28px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '18px',
                lineHeight: '22px',
                textAlign: 'center'
            },
        }
    },
    logoContainer: {
        marginTop: theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    logoWrapper: {
        position: 'relative',
        margin: theme.spacing(0, 2, 5.75, 0),
    },
    comingSoon: {
        position: 'absolute',
        left: 0,
        zIndex: 1,
        whiteSpace: 'nowrap',
        backgroundColor: alpha('#000', 0.6),
        padding: theme.spacing(4.375, 8.35),
        borderRadius: 8,
        fontSize: 18,
        color: '#FFF'
    },
    mockupWrapper: {
        width: '100%',
        position: 'relative',
        // height: 693,
        "& span": {
            "&:first-child": {
                position: ['absolute', '!important'],
                top: [-300, '!important'],
                left: [221, '!important'],
                width: [528, '!important'],
                height: [693, '!important'],
                borderRadius: [45, '!important'],
                boxShadow: ['12px 12px 25px rgb(0 0 0 / 40%)', '!important'],

            },
            [theme.breakpoints.down('md')]: {
                width: [490, '!important'],
                height: [643, '!important'],
                top: [-222, '!important'],
                left: [169, '!important'],
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            "&:nth-child(2)": {
                position: ['absolute', '!important'],
                top: [55, '!important'],
                left: [125, '!important'],
                width: [261, '!important'],
                height: [527, '!important'],
                borderRadius: [40, '!important'],
                boxShadow: ['12px 12px 25px rgb(0 0 0 / 40%)', '!important'],

            },
            [theme.breakpoints.down('md')]: {
                width: [242, '!important'],
                height: [489, '!important'],
                top: [4, '!important'],
                left: [72, '!important'],
            },
            [theme.breakpoints.down('sm')]: {
                width: [285, '!important'],
                height: [575, '!important'],
            },
        }
    }
}));

const LearnFromAnywhere = () => {

    const classes = useStyles();

    const {assetPath, appMeta} = useSelector(state => state.siteDetail);

    return (
        <div className={classes.root}>

            <Grid container wrap="nowrap" alignItems="center" className={classes.lfaWrapper}>

                {/*Content*/}
                <Grid item container direction="column" className={classes.contentWrapper}>

                    {/*Header*/}
                    <Grid item className={classes.headerWrapper}>
                        <Typography>
                            Watch From Anywhere
                        </Typography>
                    </Grid>

                    {/*Separator*/}
                    <Grid item className={classes.separatorWrapper}>
                        <hr/>
                    </Grid>

                    {/*Description*/}
                    <Grid item className={classes.descriptionWrapper}>
                        <Typography>
                            Get videobooks on the go with the {appMeta?.appName} app. Stream anywhere to watch on
                            the train, bus, car, home or wherever you learn best.
                        </Typography>
                    </Grid>

                    {/*Logo*/}
                    <Grid item container className={classes.logoContainer}>
                        <a className={classes.logoWrapper}>
                            <div className={classes.comingSoon}>Coming Soon...</div>
                            <CustomImage
                                loaded={!!assetPath}
                                src={`${assetPath}apple-store.webp`}
                                alt="Apple Store Logo"
                                width={190}
                                height={55}
                            />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.seeken"
                           target="_blank" rel="noreferrer"
                           className={classes.logoWrapper}>
                            <CustomImage
                                loaded={!!assetPath}
                                src={`${assetPath}play-store.webp`}
                                alt="Play Store Logo"
                                width={190}
                                height={55}
                            />
                        </a>
                    </Grid>

                </Grid>

                {/*Mockup Images*/}
                <Hidden xsDown>
                    <Grid item className={classes.mockupWrapper}>
                        <CustomImage
                            loaded={!!assetPath}
                            src={`${assetPath}tablet-mockup.webp`}
                            alt="Tablet Mockup"
                            layout="fill"
                        />
                        <CustomImage
                            loaded={!!assetPath}
                            src={`${assetPath}phone-mockup.webp`}
                            alt="Phone Mockup"
                            layout="fill"
                        />
                    </Grid>
                </Hidden>

            </Grid>

        </div>
    );
};

export default LearnFromAnywhere;
