//React, React Router, Formik
import React, {useEffect} from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {Grid, IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

//Redux
import {useDispatch} from "react-redux";
import {markChapterAsComplete} from "@store/Welcome/actions";

//Assets

const useStyles = makeStyles(theme => ({
    menuButton: {
        fill: theme.palette.grey.limed_spruce,
        cursor: 'pointer',
        fontSize: '28px',
    },
    menu: {
        "& .MuiPaper-root": {
            boxShadow: 'rgb(0 0 0 / 10%) 0 4px 8px 0',
            border: `1px solid ${theme.palette.grey.iron}`,
            borderRadius: '8px',
            "& .MuiMenu-list": {
                "& .MuiListItem-root": {
                    padding: theme.spacing(1.25, 2.5),
                    "&:hover": {
                        backgroundColor: theme.palette.grey.wild_sand,
                        border: 'none',
                        cursor: 'pointer',
                    },
                    "& .MuiListItemIcon-root": {
                        minWidth: 30,
                        "& .MuiSvgIcon-root": {
                            fontSize: '20px'
                        }
                    },
                    "& .MuiListItemText-root": {
                        "& .MuiTypography-root": {
                            color: theme.palette.primary.main,
                            fontSize: '15px',
                            lineHeight: 1.33,
                        }
                    },
                }
            }
        }
    }
}));

const MenuButton = ({chapterId}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //Mark chapter as complete
    const handleMarkComplete = (event) => {
        event.stopPropagation();
        dispatch(markChapterAsComplete(chapterId));
    }

    return (
        <>
            <IconButton
                aria-controls="resume-chapter-menu"
                aria-haspopup="true"
                onMouseOver={handleClick}
                style={{all: 'unset'}}
            >
                <MoreHorizIcon className={classes.menuButton} />
            </IconButton>

            <Menu
                className={classes.menu}
                id="resume-chapter-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{onMouseLeave: handleClose}}
            >
                <ListItem>
                    <ListItemIcon><CheckIcon /></ListItemIcon>
                    <ListItemText onClick={handleMarkComplete}>
                        Mark as completed
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon><VisibilityOffIcon /></ListItemIcon>
                    <ListItemText>Hide class</ListItemText>
                </ListItem>
            </Menu>
        </>
    );
};

export default MenuButton;
