//React, React Router, Formik
import React, {useEffect} from 'react';
import Link from "next/link";
import {passRouteParams} from "@helper/functions";
import {USER} from "@helper/constants/routes";

//Material UI
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, Typography, useMediaQuery} from "@material-ui/core";
import Carousel from "react-multi-carousel";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ResumeCard from "./ResumeCard/ResumeCard";
import Button from "@material-ui/core/Button";

//Redux
import {useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    lmsRow: {
        padding: theme.spacing(10.5, 5, 6.5),
    },
    carouselContainer: {
        // padding: theme.spacing(0, 5),
        "& .react-multi-carousel-list": {
            paddingTop: theme.spacing(6)
        },
        "& .react-multi-carousel-dot-list": {
            position: 'unset',
            bottom: 'unset',
            marginTop: theme.spacing(1),
            "& .react-multi-carousel-dot button": {
                borderRadius: '100px',
                width: '16px',
                height: '4px',
                background: theme.palette.grey.iron,
                border: 'none'
            },
            "& .react-multi-carousel-dot--active button": {
                background: theme.palette.grey.persian_blue
            },
        }
    },
    cardWrapper: {
        padding: theme.spacing(3, 2),
    },
    listHeader: {
        marginLeft: theme.spacing(2),
    },
    historyBtn: {
        fontSize: '13px',
        lineHeight: '26px',
        margin: theme.spacing(1, 0.75, 0, 0)
    },
    leftArrow: {
        ...theme.typography.carouselArrowButton,
        position: 'absolute',
        left: 0,
    },
    rightArrow: {
        ...theme.typography.carouselArrowButton,
        position: 'absolute',
        right: 0,
    },
    prevNavButton: {
        transform: 'rotate(180deg)'
    }
}));

const ResumeChapterList = ({resumeChapters}) => {

    const classes = useStyles();
    const theme = useTheme();

    const authUser = useSelector(state => state.auth.auth_user);

    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const responsive = {
        largeDesktop: {
            breakpoint: {max: 4000, min: 992},
            items: 2,
            slidesToSlide: 2,
            //partialVisibilityGutter: 40
        },
        desktop: {
            breakpoint: {max: 991, min: 768},
            items: 3,
            slidesToSlide: 3,
            //partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: {max: 767, min: 400},
            items: 2,
            slidesToSlide: 2,
            //partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: {max: 399, min: 0},
            items: 1,
            slidesToSlide: 1,
            //partialVisibilityGutter: 40
        }
    };

    const ArrowFix = (arrowProps) => {
        const {carouselState, children, ...restArrowProps} = arrowProps;
        return (<IconButton {...restArrowProps}> {children} </IconButton>);
    };

    return (
        <>
            <div className={classes.lmsRow}>

                <Grid container>
                    <Grid item style={{flexGrow: 1}}>
                        <Typography className={classes.listHeader} variant="h2">
                            Welcome back {authUser?.first_name}, ready for your next lesson?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Link href={`${USER.library}?tab=watched_history`}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.historyBtn}
                                component="a"
                            >
                                View Watch History
                            </Button>
                        </Link>
                    </Grid>
                </Grid>

                <div className={classes.carouselContainer}>
                    <Carousel
                        responsive={responsive}
                        // showDots={matchesUpMd}
                        renderDotsOutside={true}
                        arrows={true}
                        customLeftArrow={
                            <ArrowFix className={`${classes.carouselArrowButton} ${classes.leftArrow}`}>
                                <ArrowForwardIosIcon className={classes.prevNavButton}/>
                            </ArrowFix>
                        }
                        customRightArrow={
                            <ArrowFix className={`${classes.carouselArrowButton} ${classes.rightArrow}`}>
                                <ArrowForwardIosIcon/>
                            </ArrowFix>
                        }
                    >
                        {
                            resumeChapters.map(chapter => (
                                <div key={chapter.id} className={classes.cardWrapper}>
                                    <ResumeCard {...chapter}/>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        </>
    );
};

export default ResumeChapterList;
