//React, React Router, Formik
import React, {useEffect, useState} from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid, Typography} from "@material-ui/core";
import CourseList from "./CourseList/CourseList";

//Redux
import {useDispatch, useSelector} from "react-redux";
import {getCategoryWiseProducts, unsetCategoryWiseProducts} from "@store/Welcome/actions";

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        height: 570,
        [theme.breakpoints.down('md')]: {
            height: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(37),
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(41),
        },
    },
    wrapper: {
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(18),
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: theme.spacing(26),
        },
    },
    headerWrapper: {
        marginBottom: theme.spacing(5),
        "& .MuiTypography-root": {
            fontSize: '48px',
            fontWeight: 'bold',
            lineHeight: '52px',
            textAlign: 'center',
            [theme.breakpoints.down('md')]: {
                fontSize: '36px',
                lineHeight: '44px',
            },
        }
    },
    categoriesWrapper: {
        padding: theme.spacing(0, 7.5),
        marginBottom: theme.spacing(5.5),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 15),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 10),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 5),
        },
    },
    categoryButton: {
        borderRadius: 'none',
        margin: theme.spacing(0, 5, 1),
        padding: theme.spacing(1, 0, 1),
        color: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 2, 1),
            padding: theme.spacing(1, 0),
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
            lineHeight: '1.2em'
        },
        "&:hover": {
            color: theme.palette.grey.persian_blue,
            textDecoration: 'none'
        }
    },
    activeButton: {
        color: theme.palette.grey.persian_blue,
        borderBottom: `4px solid ${theme.palette.grey.persian_blue}`,
    }
}));

const DiscoverLearning = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [activeCategoryId, setActiveCategoryId] = useState(0);

    const categories = useSelector(state => state.siteDetail.categories);
    const categoryWiseProducts = useSelector(state => state.welcome.categoryWiseProducts);

    const handleActiveCategoryId = (categoryId) => {
        setActiveCategoryId(categoryId);
    }

    /*Fetch category wise products*/
    useEffect(() => {
        dispatch(getCategoryWiseProducts(activeCategoryId));
    },  [activeCategoryId]);


    return (
        <div className={classes.root}>
            <Grid container direction="column" alignItems="center" className={classes.wrapper}>

                {/*Header*/}
                <Grid item xs={12} className={classes.headerWrapper}>
                    <Typography>
                        Discover Best Selling Books
                    </Typography>
                </Grid>

                {/*Categories*/}
                <Grid item container justifyContent="center" className={classes.categoriesWrapper}>

                    <Button className={`${classes.categoryButton} ${activeCategoryId === 0 && classes.activeButton}`}
                            onClick={() => handleActiveCategoryId(0)}
                    >
                        All Categories
                    </Button>
                    {
                        categories && categories.map(category => (
                            <Button key={category.id}
                                    className={`${classes.categoryButton} ${category.id === activeCategoryId && classes.activeButton}`}
                                    onClick={() => handleActiveCategoryId(category.id)}
                            >
                                {category.name}
                            </Button>
                        ))
                    }
                </Grid>

                {/*Course List*/}
                {
                    categoryWiseProducts && categoryWiseProducts.length ?
                    <Grid item xs={12} container direction="column" style={{overflow: 'hidden'}}>
                        <CourseList products={categoryWiseProducts}/>
                    </Grid> : null
                }

            </Grid>
        </div>
    );
};

export default DiscoverLearning;
