//React, React Router, Formik
import React, {useState} from 'react';
import {passRouteParams} from "@helper/functions";
import {PRODUCT} from "@helper/constants/routes";
import Link from "next/link";

//Material UI
import {makeStyles, alpha} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';

//Redux
import {useSelector} from "react-redux";
import CustomImage from "@util/CustomImage/CustomImage";

//Assets

const useStyles = makeStyles(theme => ({
    card: {
        ...theme.typography.lmsCard,
        cursor: 'pointer',
        "&:hover": {
            textDecoration: 'none',
            boxShadow: `0 4px 8px 0 ${theme.palette.grey.iron}`,
            transition: 'opacity .2s ease-in-out',
        }
    },
    thumbnailWrapper: {
        position: 'relative',
        "&:hover": {
            opacity: 0.9
        },
        "& img": {
            width: '100%',
            height: 'auto',
            aspectRatio: '16/9',
            borderRadius: '8px 8px 0 0'
        },
        "& .MuiSvgIcon-root": {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            "&:nth-child(2)": {
                width: 55,
                height: 55,
                color: theme.palette.primary.main
            },
            "&:nth-child(3)": {
                width: 32,
                height: 32,
                color: '#FFF'
            },
        }
    },
    detailWrapper: {
        padding: theme.spacing(0, 2, 2)
    },
    title: {
        height: '60px',
        display: '-webkit-box',
        boxOrient: 'vertical',
        lineClamp: 3,
        overflow: 'hidden',
        "&::selection": {
            backgroundColor: theme.palette.secondary.main
        },
        "& .MuiTypography-root": {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
        }
    },
    instructorName: {
        "&::selection": {
            backgroundColor: theme.palette.secondary.main
        },
        "& .MuiTypography-root": {
            color: theme.palette.primary.main,
        }
    },
}));

const CourseCard2 = (props) => {

    const classes = useStyles();

    const assetPath = useSelector(state => state.siteDetail.assetPath);

    const {title, slug, instructors, thumbnail} = props;
    const thumbnailUrl = thumbnail?.url || `${assetPath}upload-image-placeholder.png`;

    const [hoveredThumbnail, setHoveredThumbnail] = useState(false);

    const handleMouseOver = (state) => {
        setHoveredThumbnail(state);
    }

    return (
        <Link href={{
            pathname: PRODUCT.view,
            query: {slug}
        }} passHref>
            <a className={classes.cardLink}>
                <Grid container wrap="nowrap" direction="column" className={classes.card}
                      to={passRouteParams(PRODUCT.view, {slug})}
                >
                    <Grid
                        item
                        className={classes.thumbnailWrapper}
                        onMouseEnter={() => handleMouseOver(true)}
                        onMouseLeave={() => handleMouseOver(false)}
                    >
                        <CustomImage
                            loaded={!!assetPath}
                            src={thumbnailUrl}
                            alt="Thumbnail"
                            width={1280}
                            height={720}
                        />
                        {
                            hoveredThumbnail &&
                            <>
                                <FiberManualRecordRoundedIcon />
                                <PlayArrowRoundedIcon />
                            </>

                        }
                    </Grid>
                    <Grid item container direction="column" className={classes.detailWrapper}>
                        <Grid item className={classes.title}>
                            <Typography variant="h5" align="center">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.instructorName}>
                            <Typography variant="h6" align="center">
                                {
                                    instructors && instructors.map((instructor, index, instructors) => (
                                        `${instructor.full_name}${index+1 === instructors.length ? '' : ', '}`
                                    ))
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </a>
        </Link>
    );
};

export default CourseCard2;
